<script setup lang="ts">
import { register } from "swiper/element/bundle";
import { CmsBlock } from "@shopware-pwa/types";

register();

const props = defineProps<{ content: CmsBlock }>();

const slider = ref(null);

// const teasers = props.content.slots;
const teasers = [{
  id: "1",
  media: {
    url: "https://padvstoaddvanto1csn.blob.core.windows.net/cdn-supernatural/dummy-data/wintersport-min.png",
  },
  name: "Wintersport",
  url: "/Aktivitaeten/Wintersport/"
},
{
  id: "2",
  media: {
    url: "https://padvstoaddvanto1csn.blob.core.windows.net/cdn-supernatural/dummy-data/yoga-min.png",
  },
  name: "Yoga",
  url: "/Aktivitaeten/Yoga/"
},
{
  id: "3",
  media: {
    url: "https://padvstoaddvanto1csn.blob.core.windows.net/cdn-supernatural/dummy-data/fitness-min.png",
  },
  name: "Fitness & Running",
  url: "/Aktivitaeten/Fitness-Running/"
},
{
  id: "4",
  media: {
    url: "https://padvstoaddvanto1csn.blob.core.windows.net/cdn-supernatural/dummy-data/fahrrad-min.png",
  },
  name: "Radfahren",
  url: "/Aktivitaeten/Radfahren/"
},
{
  id: "5",
  media: {
    url: "https://padvstoaddvanto1csn.blob.core.windows.net/cdn-supernatural/dummy-data/lifestyle.png",
  },
  name: "Lifestyle",
  url: "/Aktivitaeten/Lifestyle/"
},
{
  id: "6",
  media: {
    url: "https://padvstoaddvanto1csn.blob.core.windows.net/cdn-supernatural/dummy-data/bergsport-activity.png",
  },
  name: "Bergsport",
  url: "/Aktivitaeten/Bergsport/"
},
{
  id: "7",
  media: {
    url: "https://padvstoaddvanto1csn.blob.core.windows.net/cdn-supernatural/dummy-data/reisen.png",
  },
  name: "Reisen",
  url: "/Aktivitaeten/Reisen/"
},
{
  id: "8",
  media: {
    url: "https://padvstoaddvanto1csn.blob.core.windows.net/cdn-supernatural/dummy-data/homewear.png",
  },
  name: "Homewear",
  url: "/Aktivitaeten/Homewear/"
}];
</script>
<template>
  <div class="py-10 px-5 lg:px-15">
  <div class="flex flex-col gap-2">
    <div class="flex justify-between">
      <h2>{{ props.content.name }}</h2>
      <div class="flex gap-2">
        <button @click="() => slider.swiper.slidePrev()" class="bg-transparent" aria-label="previous">
          <img v-lazy-load 
            data-src="/icons/shared/arrow-down-active.svg"
            class="rotate-90 w-6 h-6"
            alt="previous"
          />
        </button>
        <button @click="() => slider.swiper.slideNext()" class="bg-transparent" aria-label="next">
          <img v-lazy-load 
            data-src="/icons/shared/arrow-down-active.svg"
            class="rotate-270 w-6 h-6"
            alt="next"
          />
        </button>
      </div>
    </div>
  </div>
  <ClientOnly>
    <swiper-container
      :loop="false"
      ref="slider"
      :slides-per-view="1.25"
      :slidesPerGroup="1"
      :centerInsufficientSlides="true"
      space-between="10"
      :centeredSlides="false"
      :breakpoints="{
          1024: {
            slidesPerView: 3.25
          }
        }"
    >
      <swiper-slide v-for="teaser in teasers" :key="teaser.id">
        <RouterLink :to="teaser.url" class="flex flex-col">
          <NuxtImg
            class="max-w-[560px]"
            :src="teaser.media.url"
            :alt="teaser.name"
          />
          <p class="block bg-dark absolute w-full max-w-[560px] bottom-0 text-white px-6 py-3">{{ teaser.name }}</p>
        </RouterLink>
      </swiper-slide>
    </swiper-container>
  </ClientOnly>
</div>
</template>
